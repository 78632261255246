import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'email']

  connect() {
    this.formTarget.addEventListener('submit', (event) => this.validate(event))

    this.emailTarget.addEventListener('focus', (event) => { this.emailLabel.classList.add('on') })
    this.emailTarget.addEventListener('blur', (event) => { this.emailLabel.classList.remove('on') })

    this.emailTarget.addEventListener('keyup', (event) => { this.toggleLabel(event, this.emailLabel) })
  }

  validate(event) {
    if (this.formTarget.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    this.formTarget.classList.add('was-validated')

    return false
  }

  toggleLabel(event, label) {
    const val = event.target.value

    if (val !== '') {
      label.classList.add('show')
    } else {
      label.classList.remove('show')
    }
  }

  get emailLabel() {
    return this.emailTarget.previousElementSibling
  }
}
