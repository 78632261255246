import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['query', 'results']

  connect() {
    this.hideResultsListener = event => this.hideResults(event)
    document.addEventListener('click', this.hideResultsListener)

    this.handleSelectionListener = event => this.handleSelection(event)
    this.element.addEventListener('resultSelected', this.handleSelectionListener)
  }

  disconnect() {
    this.reset()
    document.removeEventListener('click', this.hideResultsListener)
    this.element.removeEventListener('resultSelected', this.handleSelectionListener)
  }

  fetchResults() {
    if (this.query === '') {
      this.reset()
      return
    }

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.doSearch(), 300)
  }

  doSearch() {
    if (this.query === this.previousQuery && this.resultsTarget.innerHTML !== '') {
      return
    }
    this.previousQuery = this.query

    const url = new URL(this.data.get('url'))
    url.searchParams.append('query', this.query)

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html

        const results = this.searchResultsContainer.querySelectorAll('li')
        if (results.length > 20) {
          this.searchResultsContainer.classList.add('scroll')
        } else {
          this.searchResultsContainer.classList.remove('scroll')
        }
      })
  }

  reset() {
    this.resultsTarget.innerHTML = ''
    this.queryTarget.value = ''
  }

  hideResults(event) {
    this.resultsTarget.innerHTML = ''
  }

  navigateResults(event) {
    if (this.searchResultsController) {
      this.searchResultsController.navigateResults(event)
    }
  }

  handleSelection(event) {
    window.location.href = event.detail.href
  }

  get query() {
    return this.queryTarget.value
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(this.searchResultsContainer, 'search-results')
  }

  get searchResultsContainer() {
    return this.resultsTarget.querySelector('[data-controller=search-results]')
  }
}
