import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('keyup', (event) => { this.checkNumber(event) })
  }

  checkNumber(event) {
    event.preventDefault()
    event.stopPropagation()

    const raw = this.element.value
    const numberString = raw.replace(/,/g, '')
    const val = Math.trunc(numberString)

    if (raw === '') {
      this.element.classList.remove('is-invalid')
      this.removeErrorMessage()
    } else if (val < this.min) {
      this.element.classList.add('is-invalid')
      this.addErrorMessage()
    } else {
      this.element.classList.remove('is-invalid')
      this.removeErrorMessage()
    }
  }

  addErrorMessage() {
    if (!this.errorMessage()) {
      this.element.insertAdjacentHTML('afterend', '<div class="invalid-feedback">Max ip addresses must be greater than or equal to 1000</div>')
    }
  }

  removeErrorMessage() {
    if (this.errorMessage()) {
      this.errorMessage().remove()
    }
  }

  errorMessage() {
    return this.element.parentNode.querySelector('.invalid-feedback')
  }

  get min() {
    return Math.trunc(this.element.dataset.min)
  }
}
