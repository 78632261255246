import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    this.loadAnnouncements()
  }

  loadAnnouncements() {
    fetch(this.announcementsURL)
      .then(response => response.json())
      .then(json => this.addAnnouncements(json))
  }

  addAnnouncements(announcements) {
    announcements.forEach((announcement, idx) => { this.addAnnouncement(announcement) })
  }

  addAnnouncement(announcement) {
    const content = `
    <div class="alert alert-info" data-controller="announcement" data-target="announcement.container" data-announcement-url="${announcement.url}">
      ${announcement.message}
      <div class="text-center action">
        <a href='#' data-action="click->announcement#dismiss">Got It</a>
      </div>
    </div>
    `

    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }

  get announcementsURL() {
    return this.data.get('url')
  }
}
