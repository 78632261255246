import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'selectAll']

  connect() {
    this.formTarget.addEventListener('submit', (event) => this.validate(event))
    this.selectAllTarget.addEventListener('click', (event) => this.handleSelectAll(event))

    this.formTarget.querySelectorAll('.torrent-row').forEach((element, index) => {
      element.addEventListener('click', (event) => this.handleSelectRow(element, event))
    })
  }

  // validate that at least one torrent has been selected for editing
  validate(event) {
    const selectedTorrents = document.querySelectorAll('.torrent-row > td > input:checked')

    if (selectedTorrents.length === 0) {
      alert('You must select at least one torrent to bulk edit.')

      event.preventDefault()
      event.stopPropagation()

      return false
    }
  }

  // handle when the checkbox for all torrents has been clicked
  handleSelectAll(event) {
    const checked = this.selectAllTarget.checked

    this.formTarget.querySelectorAll('.torrent-row > td > input').forEach((element, index) => {
      element.checked = checked
    })
  }

  // make the target for the checkbox the entire row, rather than just the checkbox itself
  handleSelectRow(row, event) {
    if (event.target.type === 'checkbox') {
      return
    }

    const checkbox = row.querySelector('input')
    const checked = checkbox.checked

    checkbox.checked = !checked
  }
}
