export default class UnmappedTorrentsSource {
  static _instance

  static getInstance() {
    if (!UnmappedTorrentsSource._instance) {
      UnmappedTorrentsSource._instance = new UnmappedTorrentsSource()
      UnmappedTorrentsSource._instance.start()
    }

    return UnmappedTorrentsSource._instance
  }

  constructor() {
    this._queue = []
    this.started = false
  }

  start() {
    if (this.started) {
      return
    }

    this.started = true
    this._work()
  }

  checkForUnmappedTorrents(artist, delegate) {
    this._queue.push({ artist: artist, delegate: delegate })
  }

  _work() {
    const work = this._queue.shift()
    if (!work) {
      setTimeout(() => this._work(), 1500)
      return
    }

    const url = `/admin/torrents.json?q=${work.artist}`

    fetch(url)
      .then(response => response.json())
      .then(json => work.delegate.unmappedTorrents(json))
      .then(() => { setTimeout(() => this._work(), 1500) })
  }
}
