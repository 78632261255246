import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    const artistId = this.data.get('artist')

    if (!artistId) {
      return
    }

    const url = `/titles/${artistId}/recent_torrents`

    fetch(url)
      .then(response => response.text())
      .then(html => (this.containerTarget.innerHTML = html))
  }
}
