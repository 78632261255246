import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'mbid', 'name', 'sort', 'comment', 'active', 'aliases']

  connect() {
    this.mbidTarget.addEventListener('blur', (event) => this.doSearch(event))
    this.formTarget.addEventListener('submit', (event) => this.validate(event))
  }

  doSearch() {
    const artistId = this.mbidTarget.value

    if (!artistId) {
      return
    }

    const url = `/admin/music_brainz_artists/${artistId}`

    fetch(url)
      .then(response => response.json())
      .then(json => this.populateForm(json))
  }

  populateForm(json) {
    this.nameTarget.value = json.name
    this.sortTarget.value = json.sort
    this.commentTarget.value = json.comment
    this.activeTarget.value = json.active
    this.aliasesTarget.value = json.aliases
  }

  validate(event) {
    if (this.formTarget.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    this.formTarget.classList.add('was-validated')

    return false
  }
}
