import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['name', 'results']

  connect() {
    this.handleArtistAddedListener = event => this.handleArtistAdded(event)
    document.addEventListener('artistAdded', this.handleArtistAddedListener)

    this.handleArtistRemovedListener = event => this.handleArtistRemoved(event)
    document.addEventListener('artistRemoved', this.handleArtistRemovedListener)

    this.query = this.nameTarget.innerText.replace(/\./g, ' ')
    this.doSearch()
  }

  disconnect() {
    document.removeEventListener('click', this.handleArtistRemovedListener)
  }

  doSearch() {
    const url = new URL(this.data.get('url'))
    url.searchParams.append('query', this.query)

    fetch(url)
      .then(response => response.json())
      .then(json => this.processResults(json))
  }

  processResults(json) {
    json.forEach((artist, index) => {
      this.addArtist(artist.id, artist.name, artist.comment)
    })
  }

  handleArtistAdded(event) {
    this.darkenBadge(event.detail.artistId)
  }

  handleArtistRemoved(event) {
    this.lightenBadge(event.detail.artistId)
  }

  addArtist(id, name, comment) {
    const artistElement = document.querySelector(`#artist-${id}`)

    let badgeClass = 'badge-light'
    if (artistElement) {
      badgeClass = 'badge-dark'
    }

    const html = `<span class="artist-${id} badge link ${badgeClass}" title="${comment}" data-artist-id="${id}" data-action="click->torrent-suggestions#selectArtist">${name}</span>`

    // add new alert
    this.resultsTarget.insertAdjacentHTML('beforeend', html)
  }

  removeArtist(id, name) {

  }

  selectArtist(event) {
    const artistId = event.target.dataset.artistId
    const artistName = event.target.innerText

    this.darkenBadge(artistId)

    this.element.dispatchEvent(
      new CustomEvent('resultSelected', {
        detail: {
          href: '',
          artistId: artistId,
          artistName: artistName,
        },
        bubbles: true,
        cancelable: true,
      }),
    )
  }

  darkenBadge(artistId) {
    this.element.querySelectorAll(`span.artist-${artistId}`).forEach((element, index) => {
      element.classList.replace('badge-light', 'badge-dark')
    })
  }

  lightenBadge(artistId) {
    this.element.querySelectorAll(`span.artist-${artistId}`).forEach((element, index) => {
      element.classList.replace('badge-dark', 'badge-light')
    })
  }
}
