import { Controller } from 'stimulus'
import UnmappedTorrentsSource from '../src/unmapped_torrents_source'

export default class extends Controller {
  static targets = ['message']

  connect() {
    this.checkForUnmappedTorrents()
  }

  checkForUnmappedTorrents() {
    if (!this.artist) {
      this.unmappedTorrents([])
      return
    }

    UnmappedTorrentsSource.getInstance().checkForUnmappedTorrents(this.artist, this)
  }

  unmappedTorrents(json) {
    if (json.length === 0) {
      this.messageTarget.innerHTML = 'No unmatched torrents found for this title.'
    } else {
      this.messageTarget.innerHTML = `<a href="/admin/torrents?q=${this.artist}"><span class="oi oi-magnifying-glass"></span> There are potential unmapped torrents for this title</a>`
    }
  }

  get artist() {
    return this.data.get('artist')
  }
}
