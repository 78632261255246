import { Controller } from 'stimulus'

const upKey = 38
const downKey = 40
const enterKey = 13
const navigationKeys = [upKey, downKey, enterKey]

export default class extends Controller {
  static targets = ['results', 'result']

  connect() {
    this.currentResultIndex = 0
    this.selectCurrentResult()
  }

  navigateResults(event) {
    if (!navigationKeys.includes(event.keyCode)) {
      return
    }

    event.preventDefault()

    switch (event.keyCode) {
      case downKey:
        this.selectNextResult()
        break
      case upKey:
        this.selectPreviousResult()
        break
      case enterKey:
        this.goToSelectedResult()
        break
    }
  }

  setFocus(event) {
    this.resultTargets.forEach((element, index) => {
      element.classList.toggle('search-result--current', element.contains(event.target))
      if (element.contains(event.target)) {
        this.currentResultIndex = index
      }
    })
  }

  // private
  elementIsVisibleInViewport(el) {
    const top = el.offsetTop
    const bottom = top + el.clientHeight

    const scrollTop = this.resultsTarget.scrollTop
    const scrollBottom = scrollTop + this.resultsTarget.clientHeight

    return top >= scrollTop && bottom <= scrollBottom
  }

  selectCurrentResult(direction) {
    this.resultTargets.forEach((element, index) => {
      element.classList.toggle('search-result--current', index === this.currentResultIndex)
      if (index === this.currentResultIndex && !this.elementIsVisibleInViewport(element)) {
        element.scrollIntoView(direction === 'up')
      }
    })
  }

  selectNextResult() {
    if (this.currentResultIndex < this.resultTargets.length - 1) {
      this.currentResultIndex++
      this.selectCurrentResult('down')
    }
  }

  selectPreviousResult() {
    if (this.currentResultIndex > 0) {
      this.currentResultIndex--
      this.selectCurrentResult('up')
    }
  }

  goToSelectedResult() {
    this.element.dispatchEvent(
      new CustomEvent('resultSelected', {
        detail: {
          href: this.resultTargets[this.currentResultIndex].dataset.url,
          artistId: this.resultTargets[this.currentResultIndex].dataset.artistId,
          artistName: this.resultTargets[this.currentResultIndex].dataset.artistName,
        },
        bubbles: true,
        cancelable: true,
      }),
    )
  }
}
