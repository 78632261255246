import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['query', 'results']

  connect() {
    this.hideResultsListener = event => this.hideResults(event)
    document.addEventListener('click', this.hideResultsListener)

    this.handleSelectionListener = event => this.handleSelection(event)
    this.element.addEventListener('resultSelected', this.handleSelectionListener)
  }

  disconnect() {
    this.reset()
    document.removeEventListener('click', this.hideResultsListener)
    this.element.removeEventListener('resultSelected', this.handleSelectionListener)
  }

  fetchResults() {
    if (this.query === '') {
      this.reset()
      return
    }

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.doSearch(), 300)
  }

  doSearch() {
    if (this.query === this.previousQuery && this.resultsTarget.innerHTML !== '') {
      return
    }
    this.previousQuery = this.query

    const url = new URL(this.data.get('url'))
    url.searchParams.append('query', this.query)

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
  }

  reset() {
    this.resultsTarget.innerHTML = ''
    this.queryTarget.value = ''
  }

  hideResults(event) {
    this.resultsTarget.innerHTML = ''
  }

  navigateResults(event) {
    if (this.searchResultsController) {
      this.searchResultsController.navigateResults(event)
    }
  }

  handleSelection(event) {
    this.addArtist(event.detail.artistId, event.detail.artistName)
  }

  addArtist(id, name) {
    const html = `
      <span class="badge badge-dark" id="artist-${id}">
        <input type="hidden" name="artist_ids[]" value="${id}" />
        ${name}<span data-action="click->torrent-artists#removeArtist">&nbsp;&times;</span>
      </span>
    `

    // add new alert
    document.querySelector('#assigned-artists').insertAdjacentHTML('afterbegin', html)
    this.queryTarget.value = ''

    this.element.dispatchEvent(
      new CustomEvent('artistAdded', {
        detail: {
          artistId: id,
          artistName: name,
        },
        bubbles: true,
        cancelable: true,
      }),
    )
  }

  removeArtist(event) {
    const target = event.target.parentElement.getAttribute('id')
    const artistId = target.replace('artist-', '')
    const artistName = target.innerText

    document.querySelector(`#${target}`).remove()

    this.element.dispatchEvent(
      new CustomEvent('artistRemoved', {
        detail: {
          artistId: artistId,
          artistName: artistName,
        },
        bubbles: true,
        cancelable: true,
      }),
    )
  }

  get query() {
    return this.queryTarget.value
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, 'search-results')
  }
}
