import { Controller } from 'stimulus'
import { CountUp } from 'countup.js'

const scrollMonitor = require('scrollmonitor')

export default class extends Controller {
  static targets = ['number', 'difference', 'text', 'filter']

  connect() {
    if (this.hasTextTarget) {
      this.textTarget.classList.add('fader')
    }

    if (this.hasNumberTarget) {
      this.counter = new CountUp(this.numberTarget, this.currentNumber, { duration: 1 })
      this.watcher = scrollMonitor.create(this.numberTarget)
      this.watcher.one('fullyEnterViewport', () => {
        setTimeout(() => { this.counter.start() }, 300)
      })
    }
  }

  updateStats(event) {
    event.preventDefault()
    event.stopPropagation()

    const target = event.target

    if (this.filterTargets.includes(target)) {
      this.updateFilters(target)
    }

    if (this.hasNumberTarget) {
      this.updateNumber(target.dataset.number)
    }

    if (this.hasDifferenceTarget) {
      this.updateDifference(target.dataset.difference)
    }

    if (this.hasTextTarget) {
      this.updateTextTarget(target.dataset.text)
    }
  }

  updateFilters(activeFilter) {
    this.filterTargets.forEach((el, idx) => { el.classList.toggle('active', el === activeFilter) })
  }

  updateNumber(number) {
    this.counter.update(number)
  }

  updateDifference(number) {
    if (number > 0) {
      this.differenceTarget.innerHTML = `
        <small class="text-success mr-1">${parseFloat(number).toFixed(2)}%</small>
        <span class="oi oi-arrow-circle-top font-weight-bold text-success align-middle"></span>
      `
    } else {
      this.differenceTarget.innerHTML = `
        <small class="text-danger mr-1">${parseFloat(number).toFixed(2)}%</small>
        <span class="oi oi-arrow-circle-bottom font-weight-bold text-danger align-middle"></span>
      `
    }
  }

  updateTextTarget(text) {
    new Promise(resolve => {
      this.textTarget.classList.add('fadeout')

      const transitionEnded = (e) => {
        this.textTarget.removeEventListener('transitionend', transitionEnded)
        resolve()
      }

      this.textTarget.addEventListener('transitionend', transitionEnded)
    }).then(() => {
      this.textTarget.innerText = text
      this.textTarget.classList.remove('fadeout')
    })
  }

  get currentNumber() {
    return parseInt(this.numberTarget.innerText.replace(/,/g, ''), 10)
  }
}
