import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'email', 'password', 'confirm', 'agreeToTerms', 'submit']

  connect() {
    this.formListener = this.formTarget.addEventListener('submit', (event) => this.validate(event))

    this.agreeListener = this.agreeToTermsTarget.addEventListener('click', (event) => this.toggleSubmit(event))
    this.toggleSubmit()

    this.fieldListeners = {}
    for (const field of this.formTarget.elements) {
      if (!field.dataset.action) {
        this.fieldListeners[field] = field.addEventListener('blur', (event) => this.updateValidity(field))
      }
    }
  }

  disconnect() {
    this.formTarget.removeEventListener(this.formListener)
    this.agreeToTermsTarget.removeEventListener(this.agreeListener)

    for (const field of this.fieldListeners) {
      field.removeEventListener(this.fieldListeners[field])
    }
  }

  validate(event) {
    if (!this.isFormValid()) {
      event.preventDefault()
      event.stopPropagation()
    }

    this.formTarget.classList.add('was-validated')

    return false
  }

  updateValidity(field) {
    const validity = field.validity

    if (validity.valid) {
      field.classList.remove('is-invalid')
      field.classList.add('is-valid')
    } else {
      field.classList.remove('is-valid')
      field.classList.add('is-invalid')
    }

    this.toggleSubmit()
  }

  validateEmail() {
    const validForBrowser = this.emailTarget.validity.valid

    if (!validForBrowser) {
      this.updateValidity(this.emailTarget)
      return
    }

    const validForUs = this.isValidEmail(this.emailTarget.value)

    fetch(`/registrations/account_exists?email=${this.emailTarget.value}`)
      .then(response => response.json())
      .then(data => {
        const alreadyTaken = data.exists

        if (alreadyTaken) {
          this.emailTarget.classList.remove('is-valid')
          this.emailTarget.classList.add('is-invalid')
          this.setErrorForField(this.emailTarget, 'Email address already exists. Please use a different email address.')
        } else if (validForBrowser && !validForUs) {
          this.emailTarget.classList.remove('is-valid')
          this.emailTarget.classList.add('is-invalid')
          this.setErrorForField(this.emailTarget, 'Please enter a vaild email address.')
        } else {
          this.updateValidity(this.emailTarget)
        }

        this.toggleSubmit()
      })
  }

  validatePassword() {
    const validity = this.passwordTarget.validity

    if (validity.valueMissing) {
      this.passwordTarget.classList.remove('is-valid')
      this.passwordTarget.classList.add('is-invalid')
      this.setErrorForField(this.passwordTarget, 'Please enter a password.')
    } else if (validity.tooShort) {
      this.passwordTarget.classList.remove('is-valid')
      this.passwordTarget.classList.add('is-invalid')
      this.setErrorForField(this.passwordTarget, 'Please enter a minimum of 3 characters.')
    } else {
      this.setErrorForField(this.passwordTarget, 'Please enter a password.')
      this.updateValidity(this.passwordTarget)
    }

    if (!this.confirmTarget.validity.valueMissing) {
      this.validatePasswordConfirmation()
    }

    this.toggleSubmit()
  }

  validatePasswordConfirmation() {
    const passwordValid = this.passwordTarget.validity.valid
    const confirmValid = this.confirmTarget.validity.valid

    if (this.confirmTarget.validity.tooShort) {
      this.confirmTarget.classList.remove('is-valid')
      this.confirmTarget.classList.add('is-invalid')
      this.setErrorForField(this.confirmTarget, 'Please enter a minimum of 3 characters.')
    } else if (passwordValid && confirmValid && (this.passwordTarget.value !== this.confirmTarget.value)) {
      this.confirmTarget.classList.remove('is-valid')
      this.confirmTarget.classList.add('is-invalid')
      this.setErrorForField(this.confirmTarget, 'Password confirmation does not match.')
    } else {
      this.setErrorForField(this.confirmTarget, 'Please confirm your password.')
      this.updateValidity(this.confirmTarget)
    }

    this.toggleSubmit()
  }

  toggleSubmit(event) {
    this.submitTarget.disabled = !this.isFormValid() || !this.agreedToTerms
  }

  isFormValid() {
    return this.formTarget.checkValidity()
  }

  setErrorForField(field, error) {
    const errorField = this.findErrorDivFor(field)

    if (errorField) {
      errorField.innerHTML = error
    }
  }

  findErrorDivFor(field) {
    return field.parentElement.querySelector('.invalid-feedback')
  }

  isValidEmail(email) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.\w{2,})+$/.test(email)
  }

  get agreedToTerms() {
    return this.agreeToTermsTarget.checked
  }
}
