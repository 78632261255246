import { Controller } from 'stimulus'
import UnmappedTorrentsSource from '../src/unmapped_torrents_source'

export default class extends Controller {
  static targets = ['message']

  connect() {
    // this.checkForUnmappedTorrents()
    this.observeVisibility()
  }

  observeVisibility() {
    const options = {
      threshold: 1.0,
    }

    this.observer = new IntersectionObserver((entries, observer) => { this.checkForUnmappedTorrents(entries) }, options)
    this.observer.observe(this.element)
  }

  checkForUnmappedTorrents(entries) {
    if (!entries[0].isIntersecting) {
      return
    }

    this.observer.disconnect()
    this.observer = null

    if (!this.name) {
      this.unmappedTorrents([])
      return
    }

    UnmappedTorrentsSource.getInstance().checkForUnmappedTorrents(this.name, this)
  }

  unmappedTorrents(json) {
    if (json.length > 0) {
      this.thumbnail.insertAdjacentHTML('beforeend', '<span><i class="fas fa-exclamation-circle"></i></span>')
    }
  }

  get name() {
    return this.data.get('name')
  }

  get thumbnail() {
    return this.element.querySelector('.artist-thumbnail')
  }
}
