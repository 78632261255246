import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['admin', 'owner']

  updateRoles(event) {
    event.preventDefault()
    event.stopPropagation()

    fetch(this.url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-Token': this.csrf,
      },
      method: 'PUT',
      body: JSON.stringify({
        admin: this.admin,
        owner: this.owner,
      }),
    })
      .then(response => response.json())
      .then(json => this.updateStatus(json.status, json.message))
  }

  updateStatus(status, message) {
    const html = `<div class="alert alert-${status}" role="alert">${message}</div>`

    // remove any existing alerts
    if (document.querySelector('div.alert')) {
      document.querySelector('div.alert').remove()
    }

    // add new alert
    document.querySelector('nav').insertAdjacentHTML('afterend', html)
  }

  get url() {
    return this.data.get('url')
  }

  get admin() {
    return this.adminTarget.checked
  }

  get owner() {
    return this.ownerTarget.checked
  }

  get csrf() {
    return document.querySelector("meta[name='csrf-token']").content
  }
}
