import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'name']

  connect() {
    this.formTarget.addEventListener('submit', (event) => this.validate(event))
    this.nameTarget.addEventListener('keydown', (event) => { this.handleEnterKey(event) })
  }

  validate(event) {
    const valid = this.formTarget.checkValidity()

    if (valid === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    this.formTarget.classList.add('was-validated')

    return valid
  }

  handleEnterKey(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      event.stopPropagation()

      if (this.validate(event) === true) {
        this.formTarget.submit()
      }
    }

    return false
  }
}
