import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['query', 'results', 'list']

  connect() {
    this.hideResultsListener = event => this.hideResults(event)
    document.addEventListener('click', this.hideResultsListener)

    this.handleSelectionListener = event => this.handleSelection(event)
    this.element.addEventListener('resultSelected', this.handleSelectionListener)
  }

  disconnect() {
    this.reset()
    document.removeEventListener('click', this.hideResultsListener)
    this.element.removeEventListener('resultSelected', this.handleSelectionListener)
  }

  fetchResults() {
    if (this.query === '') {
      this.reset()
      return
    }

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.doSearch(), 300)
  }

  doSearch() {
    if (this.query === this.previousQuery && this.resultsTarget.innerHTML !== '') {
      return
    }
    this.previousQuery = this.query

    const url = new URL(this.data.get('url'))
    url.searchParams.append('query', this.query)

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html

        const results = this.searchResultsContainer.querySelectorAll('li')
        if (results.length > 20) {
          this.searchResultsContainer.classList.add('scroll')
        } else {
          this.searchResultsContainer.classList.remove('scroll')
        }
      })
  }

  reset() {
    this.resultsTarget.innerHTML = ''
    this.queryTarget.value = ''
  }

  hideResults(event) {
    this.resultsTarget.innerHTML = ''
  }

  navigateResults(event) {
    if (this.searchResultsController) {
      this.searchResultsController.navigateResults(event)
    }
  }

  handleSelection(event) {
    this.addTitle(event.detail.artistId, event.detail.artistName)
  }

  addTitle(id, name) {
    const html = `
      <tr id="title-${id}">
        <input type="hidden" name="title_ids[]" value="${id}" id="title-ids-${id}" />
        <td class="text-truncate">${name}</td>
        <td class="action text-right text-nowrap">
          <a href="#" data-action="click->title-search#removeTitle" data-remove-id="title-${id}">Remove</a>
        </td>
      </tr>
    `

    // add to the top of the list of titles
    this.listTarget.classList.remove('d-none')
    this.listTarget.insertAdjacentHTML('afterbegin', html)
    this.queryTarget.value = ''
  }

  removeTitle(event) {
    event.preventDefault()

    const target = event.target.getAttribute('data-remove-id')

    document.getElementById(target).remove()

    const remainingTitles = this.listTarget.querySelectorAll('tbody tr')
    if (remainingTitles.length === 0) {
      this.listTarget.classList.add('d-none')
    }
  }

  get query() {
    return this.queryTarget.value
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(this.searchResultsContainer, 'search-results')
  }

  get searchResultsContainer() {
    return this.resultsTarget.querySelector('[data-controller=search-results]')
  }
}
