import { Controller } from 'stimulus'

import * as d3 from 'd3'
import scaleCluster from 'd3-scale-cluster'
import * as topojson from 'topojson'

export default class extends Controller {
  static targets = ['map']

  connect() {
    this.width = 600
    this.height = 400
    this.activeCountry = d3.select(null)
    this.activeRegion = d3.select(null)
    this.rotated = false

    // blues
    this.colorRange = ['#f7fbff', '#deebf7', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#08519c', '#08306b']
    // purples
    // this.colorRange = ['#fcfbfd', '#efedf5', '#dadaeb', '#bcbddc', '#9e9ac8', '#807dba', '#6a51a3', '#54278f', '#3f007d']
    // PuBu
    // this.colorRange = ['#fff7fb', '#ece7f2', '#d0d1e6', '#a6bddb', '#74a9cf', '#3690c0', '#0570b0', '#045a8d', '#023858']
    // YlGnBu
    // this.colorRange = ['#ffffd9', '#edf8b1', '#c7e9b4', '#7fcdbb', '#41b6c4', '#1d91c0', '#225ea8', '#253494', '#081d58']

    this.artistId = this.data.get('artist-id')

    this.projection = d3.geoMercator()
      .rotate([0, 0])
      .scale(90)
      .translate([this.width / 2, this.height / 2 + 80])

    this.zoom = d3.zoom().on('zoom', (event) => this.zoomed(event))
    this.path = d3.geoPath().projection(this.projection)

    this.tooltip = d3.select(this.mapTarget)
      .append('div')
      .attr('class', 'country-tooltip')

    this.svg = d3.select(this.mapTarget)
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '0 0 600 400')
      .on('click', (event) => this.stopped(event), true)

    this.svg.append('rect')
      .attr('class', 'background')
      .attr('width', this.width)
      .attr('height', this.height)
      .on('click', () => this.reset())

    this.g = this.svg.append('g')

    this.svg.call(this.zoom)

    this.addZoomControls()

    this.downloadsByCountry = JSON.parse(this.data.get('download-data'))

    const worldDataURL = this.data.get('world-data')

    d3.json(worldDataURL)
      .then((data) => this.ready(data))

    document.addEventListener('locationChange', (event) => {
      if (event.detail.source !== this.mapTarget) {
        if (event.detail.subdivision && event.detail.country) {
          const selector = this.subdivisionDataNameFor(event.detail.country, event.detail.subdivision)
          d3.select(`[data-region-name=${selector}]`).dispatch('click', { detail: { swallow: true } })
        } else if (event.detail.country) {
          d3.select(`#country_${event.detail.country}`).dispatch('click', { detail: { swallow: true } })
        } else {
          this.reset(false)
        }
      }
    })
  }

  addZoomControls() {
    const controls = d3.select(this.mapTarget)
      .append('div')
      .attr('class', 'zoom-control')

    controls.append('button')
      .attr('class', 'zoom-in-control')
      .attr('title', 'Zoom In Control')
      .text('+')
      .on('click', (e) => this.handleZoomClick('in'))

    controls.append('button')
      .attr('class', 'zoom-out-control')
      .attr('title', 'Zoom Out Control')
      .html('&ndash;')
      .on('click', (e) => this.handleZoomClick('out'))
  }

  ready(world) {
    const dataset = Object.values(this.downloadsByCountry).map((d) => d.downloads).reverse()

    const colorizer = scaleCluster()
      .domain(dataset)
      .range(this.colorRange)

    this.addCountries(world, colorizer)
  }

  addCountries(world, colorizer) {
    const countries = topojson.feature(world, world.objects.countries).features
    // console.log(countries)

    this.g
      .append('g')
      .attr('id', 'countries')
      .attr('class', 'countries')
      .selectAll('.country')
      .data(countries)
      .enter().append('path')
      .attr('class', 'country')
      .attr('d', this.path)
      .attr('id', d => `country_${d.id}`)
      .style('fill', d => {
        const entry = this.downloadsByCountry[d.id] || {}
        return colorizer(entry.downloads || 0)
      })
      .on('click', (event, d) => this.handleCountryClick(event, d))
      .on('mouseover', (event, d) => this.handleMouseOver(event, d, d => {
        const entry = this.downloadsByCountry[d.id] || {}
        return entry.downloads || 0
      }))
      .on('mouseout', (event, d) => this.handleMouseOut(event, d))
      .on('mousemove', (event, d) => this.handleMouseMove(event, d))
  }

  redrawCountries() {
    d3.selectAll('.country').attr('d', this.path)
  }

  handleCountryClick(event, d) {
    this.activeCountry
      .classed('active', false)
      .style('display', null)

    this.activeCountry = d3.select(`#country_${d.id}`)

    this.hideAllCities()
    this.hideAllRegions()

    // russia, fiji, the united states, and new zealand are on both ends of the map,
    // so rotate the projection a little to get them on one end of the map before zooming
    if (['FJ', 'RU', 'NZ'].includes(d.id)) {
      this.rotateProjection(-12, () => {
        this.loadAndDisplayRegions(d, dispatchEvent)
        this.zoomCountry(this.activeCountry, d)
      })
    } else if (d.id === 'US') {
      this.rotateProjection(12, () => {
        this.loadAndDisplayRegions(d, dispatchEvent)
        this.zoomCountry(this.activeCountry, d)
      })
    } else {
      this.resetProjection(() => {
        this.loadAndDisplayRegions(d, dispatchEvent)
        this.zoomCountry(this.activeCountry, d)
      })
    }
  }

  handleRegionClicked(event, d) {
    const node = d3.select(`#region_${d.id}`).node()

    if (d && this.activeRegion !== node) {
      this.activeRegion = node

      const country = d.id.slice(0, 2)

      this.zoomRegion(this.activeRegion, d)
      this.loadAndDisplayCities(d, country)
      this.dispatchLocationChange(this.artistId, country, d.properties.name_en)
    } else {
      this.activeRegion = d3.select(null)
      this.hideAllCities()
      this.handleCountryClick(event, this.activeCountry.datum())
    }
  }

  // zoom in on a the selected country
  zoomCountry(node, d) {
    // this.activeCountry.classed('active', true)

    // make all countries become almost invisible
    this.svg
      .selectAll('.country')
      .transition()
      .duration(500)
      .style('opacity', 0.05)

    // make the selected country fully opaque
    node
      .style('opacity', 1)
      .classed('active', true)

    this.doZoom(d)
  }

  zoomRegion(node, d) {
    // console.log('zoomRegion(', node, ', ', d, ')')
    this.doZoom(d)
  }

  doZoom(d) {
    const bounds = this.path.bounds(d)
    const dx = bounds[1][0] - bounds[0][0]
    const dy = bounds[1][1] - bounds[0][1]
    const x = (bounds[0][0] + bounds[1][0]) / 2
    const y = (bounds[0][1] + bounds[1][1]) / 2
    const scale = Math.max(1, Math.min(100, 0.9 / Math.max(dx / this.width, dy / this.height)))
    const translate = [this.width / 2 - scale * x, this.height / 2 - scale * y]

    const settings = d3.zoomIdentity
      .translate(translate[0], translate[1])
      .scale(scale)

    this.svg
      .transition()
      .duration(250)
      .call((s, settings) => {
        this.zoom.transform(s, settings)
      }, settings)
  }

  handleMouseOver(event, d, callback) {
    d3.select(event.toElement).classed('selected', true)

    if (d.properties.name) {
      const dls = callback(d)

      this.tooltip
        .html(this.tooltipText(d, dls))
        .style('left', (event.offsetX + 7) + 'px')
        .style('top', (event.offsetY - 15) + 'px')
        .style('display', 'block')
        .style('opacity', 1)
    }
  }

  tooltipText(d, downloads) {
    return `<span class="title">${d.displayName || d.properties.name}</span><span class="data">Downloads: ${downloads.toLocaleString('en') || 0}</span>`
  }

  handleMouseOut(event, d) {
    d3.select(event.fromElement).classed('selected', false)

    this.tooltip
      .style('opacity', 0)
      .style('display', 'none')
  }

  handleMouseMove(event, d) {
    this.tooltip
      .style('left', (event.offsetX + 7) + 'px')
      .style('top', (event.offsetY - 15) + 'px')
  }

  handleZoomClick(direction) {
    const multiplier = (direction === 'in') ? 1.5 : 0.66
    const z = d3.zoomTransform(d3.select('svg').node()).k * multiplier

    this.svg
      .transition()
      .duration(300)
      .call((s, z) => this.zoom.scaleTo(s, z), z)
  }

  reset(emitEvent = true) {
    this.activeCountry
      .classed('active', false)
      .style('display', 'block')

    this.activeCountry = d3.select(null)

    this.resetProjection(() => {
      this.svg
        .transition()
        .duration(500)
        .call((selection, settings) => this.zoom.transform(selection, settings), d3.zoomIdentity)

      this.svg
        .selectAll('.country')
        .transition()
        .duration(500)
        .style('opacity', 1)

      this.hideAllCities()
      this.hideAllRegions()
    })

    if (emitEvent) {
      this.dispatchLocationChange(this.artistId)
    }
  }

  zoomed(event) {
    this.g.attr('transform', event.transform)
  }

  stopped(event) {
    if (event.defaultPrevented) {
      event.stopPropagation()
    }
  }

  // rotate the projection -20 degrees on the X axis
  rotateProjection(degrees, callback) {
    if (this.rotated) {
      callback()
      return
    }

    this.rotated = true

    d3.transition()
      .duration(100)
      .tween('rotate', () => {
        const r = d3.interpolate(this.projection.rotate(), [degrees, 0])
        return (t) => {
          this.projection.rotate(r(t))
          this.redrawCountries()
        }
      })
      .on('end', callback)
  }

  // reset the projection back to the default rotation on the X axis
  resetProjection(callback) {
    if (!this.rotated) {
      callback()
      return
    }

    this.rotated = false

    d3.transition()
      .duration(100)
      .tween('rotate', () => {
        const r = d3.interpolate(this.projection.rotate(), [0, 0])
        return (t) => {
          this.projection.rotate(r(t))
          this.redrawCountries()
        }
      })
      .on('end', () => {
        callback()
      })
  }

  loadAndDisplayRegions(d, emitEvent = true) {
    if (d3.select(`#regions_${d.id}`).empty()) {
      const regionData = d3.json(`/topojson/states_${d.id}.topo.json`)
      const downloadData = d3.json(`/titles/${this.artistId}/downloads/${d.id}`)

      Promise.all([regionData, downloadData])
        .then((values) => this.addRegions(d, values[0], values[1]))
    } else {
      this.showRegions(d)
    }

    if (emitEvent) {
      this.dispatchLocationChange(this.artistId, d.id)
    }
  }

  addRegions(d, data, downloads) {
    // console.log('REGION', data)
    if (data.objects.states.type == null) {
      return
    }

    // console.log('DOWNLOADS', downloads)

    const dataset = Object.values(downloads).map(d => d.total)

    // console.log('DATASET', dataset)

    const colorizer = scaleCluster()
      .domain(dataset)
      .range(this.colorRange)

    const regions = topojson.feature(data, data.objects.states).features
    regions.forEach((region, index) => {
      const entry = downloads[region.properties.name_en] || {}
      region.downloads = entry.total || 0
    })

    const country = d3.select(`#country_${d.id}`)

    this.g
      .append('g')
      .attr('id', `regions_${d.id}`)
      .attr('class', 'regions')
      .selectAll('.region')
      .data(regions)
      .enter().append('path')
      .attr('class', 'region')
      .attr('d', this.path)
      .attr('id', region => `region_${region.id}`)
      .attr('data-region-name', region => this.subdivisionDataNameFor(d.id, region.properties.name_en))
      .style('fill', region => colorizer(region.downloads))
      .on('mouseover', (event, d) => this.handleMouseOver(event, d, d => d.downloads))
      .on('mouseout', (event, d) => this.handleMouseOut(event, d))
      .on('click', (event, d) => this.handleRegionClicked(event, d))

    country.style('display', 'none')
  }

  showRegions(d) {
    const region = this.svg.select(`#regions_${d.id}`)

    region.selectAll('.region').attr('d', this.path)
    region.style('display', 'block')
    this.activeCountry.style('display', 'none')
  }

  hideAllRegions() {
    this.svg.selectAll('g.regions').style('display', 'none')
  }

  loadAndDisplayCities(d, countryCode) {
    this.hideAllCities()

    if (d3.select(`#cities_${countryCode}`).empty()) {
      // console.log('loading cities for country ', countryCode)

      const cityData = d3.json(`/topojson/cities_${countryCode}.topo.json`)
      const downloadData = d3.json(`/titles/${this.artistId}/downloads/${countryCode}?scope=city`)

      Promise.all([cityData, downloadData])
        .then((values) => this.addCities(d, countryCode, values[0], values[1]))
    } else {
      // console.log('showing cities for country ', countryCode)

      this.showCities(d, countryCode)
    }
  }

  addCities(d, countryCode, data, downloads) {
    if (data.objects.cities.type == null) {
      return
    }

    const cities = topojson.feature(data, data.objects.cities).features

    // add downloads and displayName to each city
    cities.forEach((city, index) => {
      const entry = downloads[`${city.properties.state}-${city.properties.name}`] || {}
      city.downloads = entry.total || 0
      city.displayName = `${city.properties.name}, ${city.properties.state}`
    })

    // calculate size of city radius based on size of country
    const radius = this.cityRadius(d)

    this.g
      .append('g')
      .attr('id', `cities_${countryCode}`)
      .attr('class', 'cities')
      .selectAll('.city')
      .data(cities.filter(d => d.downloads > 0))
      .enter().append('path')
      .attr('class', 'city')
      .attr('id', city => `city_${city.name}`)
      .attr('d', this.path.pointRadius(radius))
      .on('mouseover', (event, d) => this.handleMouseOver(event, d, d => d.downloads))
      .on('mouseout', (event, d) => this.handleMouseOut(event, d))
  }

  showCities(d, countryCode) {
    const city = this.svg.select(`#cities_${countryCode}`)

    city.selectAll('.city').attr('d', this.path)
    city.style('display', 'block')
  }

  hideAllCities() {
    this.svg.selectAll('g.cities').style('display', 'none')
  }

  cityRadius(d) {
    const bounds = this.path.bounds(d)
    const widthScale = (bounds[1][0] - bounds[0][0]) / this.width
    const heightScale = (bounds[1][1] - bounds[0][1]) / this.height
    const z = 0.96 / Math.max(widthScale, heightScale)

    return 5 / z
  }

  subdivisionDataNameFor(country, subdivision) {
    return `region_${country}_${subdivision.replace(/\s/g, '_')}`
  }

  dispatchLocationChange(artist, country, subdivision) {
    this.mapTarget.dispatchEvent(
      new CustomEvent('locationChange', {
        detail: {
          source: this.mapTarget,
          artist: artist,
          country: country,
          subdivision: subdivision,
        },
        bubbles: true,
        cancelable: true,
      }),
    )
  }
}
