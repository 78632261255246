import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  dismiss(event) {
    event.preventDefault()
    event.stopPropagation()

    fetch(this.url, {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'PUT',
    })

    this.removeAnnouncement()
  }

  removeAnnouncement() {
    this.containerTarget.remove()
  }

  get url() {
    return this.data.get('url')
  }
}
