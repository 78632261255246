/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import 'jquery-ui'
import 'jquery-tageditor'
import 'popper.js'
import 'bootstrap'
import 'video.js'

import Rails from '@rails/ujs'
// import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import LocalTime from 'local-time'

import 'channels'

// start stimulus
const application = Application.start()

// load all the stimulus controllers
const context = require.context('controllers', true, /.js$/)
application.load(definitionsFromContext(context))

// start rails
Rails.start()
// Turbolinks.start()
ActiveStorage.start()
LocalTime.start()

// copy all of the fonts
require.context('fonts', true)

// copy all of the images
require.context('images', true)

// setup bootstrap tooltips and popovers
$(function() {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({ html: true })
})

// admin artist torrent mapping jquery-tageditor
$(function() {
  $('#torrent-mapping').tagEditor(
    {
      autocomplete: {
        delay: 0,
        position: { collision: 'flip' },
        source: '/titles.json',
        minLength: 3,
        response: (event, ui) => {
          ui.content.forEach((item, index) => {
            item.value = item.id
            item.label = item.name
          })
        },
      },
      forceLowercase: false,
      placeholder: 'Artist mapping...',
    },
  )
});

// http://code.accursoft.com/caret - 1.3.7
(function($) {
  function focus(target) {
    if (!document.activeElement || document.activeElement !== target) {
      target.focus()
    }
  }

  $.fn.caret = function(pos) {
    const target = this[0]
    const isContentEditable = target && target.contentEditable === 'true'

    if (arguments.length === 0) {
      // get
      if (target) {
        // HTML5
        if (window.getSelection) {
          // contenteditable
          if (isContentEditable) {
            focus(target)
            const selection = window.getSelection()

            // Opera 12 check
            if (!selection.rangeCount) {
              return 0
            }

            const range1 = selection.getRangeAt(0)
            const range2 = range1.cloneRange()
            range2.selectNodeContents(target)
            range2.setEnd(range1.endContainer, range1.endOffset)

            return range2.toString().length
          }

          // textarea
          return target.selectionStart
        }

        // IE < 9
        if (document.selection) {
          focus(target)

          // contenteditable
          if (isContentEditable) {
            const range1 = document.selection.createRange()
            const range2 = document.body.createTextRange()

            range2.moveToElementText(target)
            range2.setEndPoint('EndToEnd', range1)

            return range2.text.length
          }

          // textarea
          const range = target.createTextRange()
          const range2 = document.selection.createRange().duplicate()
          const bookmark = range2.getBookmark()

          range.moveToBookmark(bookmark)

          let pos = 0
          while (range.moveStart('character', -1) !== 0) {
            pos++
          }

          return pos
        }

        // Addition for jsdom support
        if (target.selectionStart) {
          return target.selectionStart
        }
      }

      // not supported
      return
    }

    // set
    if (target) {
      if (pos === -1) {
        pos = this[isContentEditable ? 'text' : 'val']().length
      }

      // HTML5
      if (window.getSelection) {
        if (isContentEditable) {
          focus(target)
          window.getSelection().collapse(target.firstChild, pos)
        } else {
          target.setSelectionRange(pos, pos)
        }
      } else if (document.body.createTextRange) { // IE < 9
        if (isContentEditable) {
          const range = document.body.createTextRange()

          range.moveToElementText(target)
          range.moveStart('character', pos)
          range.collapse(true)
          range.select()
        } else {
          const range = target.createTextRange()

          range.move('character', pos)
          range.select()
        }
      }

      if (!isContentEditable) {
        focus(target)
      }
    }

    return this
  }
})(jQuery)

// Add gradient to table-responsive-sm div to indicate horizontal scrolling on small devices
function updateScrollGradient(scrollElement) {
  const scrollPos = scrollElement.scrollLeft
  const divWidth = scrollElement.scrollWidth - scrollElement.clientWidth

  if (scrollPos === 0) {
    scrollElement.classList.remove('gradient-left')
  }

  if (scrollPos > 0) {
    scrollElement.classList.add('gradient-left')
  }

  if (scrollPos < divWidth) {
    scrollElement.classList.add('gradient-right')
  }

  if (scrollPos === divWidth) {
    scrollElement.classList.remove('gradient-right')
  }
}

const scrollElement = document.getElementById('scroll-gradient')
if (scrollElement) {
  scrollElement.addEventListener('scroll', (event) => {
    updateScrollGradient(scrollElement)
  })
}
