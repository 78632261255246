import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['card']

  connect() {
    this.refreshInterval = 1000 * 30

    document.addEventListener('ReportGenerated', (event) => { this.handleReportGenerated(event) })

    this.scheduleUpdate()
  }

  handleReportGenerated(event) {
    event.stopPropagation()
    this.fetchDownloads()
  }

  fetchDownloads() {
    Rails.ajax({
      url: this.cardTarget.dataset.url,
      type: 'GET',
      success: (data) => { this.updateCard(data) },
    })
  }

  updateCard(data) {
    this.cardTarget.innerHTML = data.querySelector('div.card').innerHTML
    this.scheduleUpdate()
  }

  scheduleUpdate() {
    if (this.processing()) {
      setTimeout(() => { this.fetchDownloads() }, this.refreshInterval)
    }
  }

  processing() {
    const pending = this.cardTarget.querySelectorAll('[data-status=pending]')
    if (pending.length > 0) {
      return true
    }

    const running = this.cardTarget.querySelectorAll('[data-status=running]')
    if (running.length > 0) {
      return true
    }

    return false
  }
}
