import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'first', 'last', 'email']

  connect() {
    this.formTarget.addEventListener('submit', (event) => this.validate(event))

    this.firstTarget.addEventListener('keyup', (event) => { this.toggleLabel(event, this.firstLabel) })
    this.firstTarget.addEventListener('keydown', (event) => { this.handleEnterKey(event) })

    this.lastTarget.addEventListener('keyup', (event) => { this.toggleLabel(event, this.lastLabel) })
    this.lastTarget.addEventListener('keydown', (event) => { this.handleEnterKey(event) })

    this.emailTarget.addEventListener('keyup', (event) => { this.toggleLabel(event, this.emailLabel) })
    this.emailTarget.addEventListener('keydown', (event) => { this.handleEnterKey(event) })
  }

  validate(event) {
    const valid = this.formTarget.checkValidity()

    if (valid === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    this.formTarget.classList.add('was-validated')

    return valid
  }

  handleEnterKey(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      event.stopPropagation()

      if (this.validate(event) === true) {
        this.formTarget.submit()
      }
    }

    return false
  }

  toggleLabel(event, label) {
    const val = event.target.value

    if (val !== '') {
      label.classList.add('show')
    } else {
      label.classList.remove('show')
    }
  }

  get firstLabel() {
    return this.firstTarget.previousElementSibling
  }

  get lastLabel() {
    return this.lastTarget.previousElementSibling
  }

  get emailLabel() {
    return this.emailTarget.previousElementSibling
  }
}
