import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['button']

  connect() {
    this.buttonTarget.addEventListener('click', (event) => { this.generate(event) })
  }

  generate(event) {
    event.preventDefault()
    event.stopPropagation()

    const target = event.target

    this.sendGenerateRequest(target)
    this.spin(target)
  }

  sendGenerateRequest(target) {
    const href = target.getAttribute('href').split('?')[0]
    const data = new FormData()

    data.append('list_id', target.dataset.listId)

    this.spin(target)

    Rails.ajax({
      url: href,
      type: 'post',
      data: data,
      complete: (data) => { this.finishSpin(target) },
    })
  }

  spin(target) {
    target.innerHTML = '<span class="spinner-border spinner-border-sm text-primary"></span>'
    target.classList.add('spinner')
  }

  finishSpin(target) {
    target.classList.remove('spinner')
    target.innerHTML = 'Generate'
    target.blur()

    Rails.fire(target, 'ReportGenerated', { listId: target.dataset.listId })
  }

  baseHostname() {
    const { protocol, host } = window.location

    return `${protocol}//${host}`
  }
}
