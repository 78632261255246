import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['query', 'results', 'artists']

  connect() {
    this.hideResultsListener = event => this.hideResults(event)
    document.addEventListener('click', this.hideResultsListener)

    this.handleSelectionListener = event => this.handleSelection(event)
    this.element.addEventListener('resultSelected', this.handleSelectionListener)
  }

  disconnect() {
    this.reset()
    document.removeEventListener('click', this.hideResultsListener)
    this.element.removeEventListener('resultSelected', this.handleSelectionListener)
  }

  fetchResults() {
    if (this.query === '') {
      this.reset()
      return
    }

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.doSearch(), 300)
  }

  doSearch() {
    if (this.query === this.previousQuery && this.resultsTarget.innerHTML !== '') {
      return
    }
    this.previousQuery = this.query

    const url = new URL(this.data.get('url'))
    url.searchParams.append('query', this.query)

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
  }

  reset() {
    this.resultsTarget.innerHTML = ''
    this.queryTarget.value = ''
  }

  hideResults(event) {
    this.resultsTarget.innerHTML = ''
  }

  navigateResults(event) {
    if (this.searchResultsController) {
      this.searchResultsController.navigateResults(event)
    }
  }

  handleSelection(event) {
    this.addArtist(event.detail.artistId, event.detail.artistName)
  }

  addArtist(id, name) {
    const html = `
      <tr id="artist-${id}" data-artist-name="${name}">
        <input type="hidden" name="artist_list[artist_ids][]" value="${id}" />
        <td class="text-truncate">${name}</td>
        <td class="action text-right text-nowrap"><a href="#" data-action="click->admin-artist-lists#removeArtist">Remove</a></td>
      </tr>
    `

    // add new alert
    this.artistsTarget.insertAdjacentHTML('beforeend', html)
    this.queryTarget.value = ''
  }

  removeArtist(event) {
    const target = event.target.closest('tr')
    const name = target.dataset.artistName

    if (window.confirm(`Are you sure you want to remove ${name}`)) {
      target.remove()
    }
  }

  get query() {
    return this.queryTarget.value
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, 'search-results')
  }
}
