import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    document.addEventListener('locationChange', (event) => {
      if (event.detail.source !== this.containerTarget) {
        this.handleLocationChange(event)
      }
    })

    this.setupLinkListeners()
  }

  setupLinkListeners() {
    this.containerTarget.querySelectorAll('a').forEach((element, index) => {
      element.addEventListener('click', (event) => this.handleClick(event))
    })
  }

  handleClick(event) {
    event.preventDefault()
    event.stopPropagation()

    const target = event.target

    this.loadDownloadList(target.href)
    this.dispatchLocationChange(target.dataset.artist, target.dataset.country, target.dataset.subdivision)
  }

  handleLocationChange(event) {
    // ignore if no artist is defined
    if (event.detail.artist == null) {
      return
    }

    const params = {}

    if (event.detail.country) {
      params.country = event.detail.country
    }

    if (event.detail.subdivision) {
      params.subdivision = event.detail.subdivision
    }

    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    this.loadDownloadList(`/titles/${event.detail.artist}/downloads?${queryString}`)
  }

  loadDownloadList(url) {
    this.showSpinner()

    fetch(url)
      .then(response => response.text())
      .then(html => (this.containerTarget.innerHTML = html))
      .then(() => this.setupLinkListeners())
      .then(() => this.hideSpinner())
  }

  showSpinner() {
    document.querySelector('#downloads-working').style.display = 'block'
  }

  hideSpinner() {
    document.querySelector('#downloads-working').style.display = 'none'
  }

  dispatchLocationChange(artist, country, subdivision) {
    this.containerTarget.dispatchEvent(
      new CustomEvent('locationChange', {
        detail: {
          source: this.containerTarget,
          artist: artist,
          country: country,
          subdivision: subdivision,
        },
        bubbles: true,
        cancelable: true,
      }),
    )
  }
}
